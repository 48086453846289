body {
  margin: 0;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #6a32f9 !important;
  color: #14195F !important;
}
.login-box-msg{    padding: 0 20px 30px !important;}
p {font-size: 15px;}
code {font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;}.color-white{color: #FFFFFF !important;}
.content {padding: 20px;}
.header {padding: 10px;background: #edf2f4;border-bottom: 1px solid #999;}
.header a {color: #0072ff;text-decoration: none;margin-left: 20px;margin-right: 5px;
}.header a:hover {color: #8a0f53;}.fa-trash{color: #e92626;}.fa-eye{color: #bd960c;}
.content-wrapper>.content {padding: 0 0.5rem 1rem 0.5rem;}.card {border-radius: unset !important;margin: 0 !important;}
.header small {color: #666;}.pr-5{padding-right: 5px !important;}.brand-image{width: 85%;}
.header .active {color: #2c7613;}.login-logo{margin-bottom: 5px !important;}
.login-jojo{margin-top: 5px; width: 350px;}.login-jiji{width: 350px;}
[class*=sidebar-dark-] .nav-sidebar>.nav-item.menu-open>.nav-link {background-color: #14195F;color: #FFFFFF !important;}
[class*=sidebar-dark-] .nav-sidebar>.nav-item:hover>.nav-link {background-color: #814fff;}
[class*=sidebar-dark-] .nav-sidebar>.nav-item>.nav-link:focus {background-color: #814fff;}
.brand-primary-btn{background-color: #6A32F9 !important;color: #F4F7FE !important;font-size: 13px !important;border: 1px solid #6A32F9 !important;font-weight: bolder !important;}.brand-secondary-btn{background-color: #FFC700 !important;color: #14195F !important;font-size: 13px !important;border: 1px solid #FFC700 !important;font-weight: bolder !important;}.exam-result{padding-top: 30px;background-color: #14195F; color: #ffffff;}.coins-board{background: #0E1245;margin: auto;width: 90%;}.coins-board .value{font-size: 24px;font-weight: 500;}.content-wrapper{min-height: 550px;}.brand-primary{color: #6A32F9;}.brand-second-color{color: #FFC700;}.btr{border-top-right-radius: 16px;}.btl{border-top-left-radius: 16px;}.bbl{border-bottom-left-radius: 16px;}.bbr{border-bottom-right-radius: 16px;}.tbl-border {border: 1px solid #AAAEBD4D;padding: 15px;    background: #0E1245;}.login-box{/* margin: 30px auto; background: url('/public/dist/img/bg-mask.svg') center center; padding: 60px 0px;*/ width: 90% !important;    margin: auto;}.nav-pills .nav-link {border-radius: 3px !important;}
.font-weight-light{color:#6a32f9;}.brand-link{ background: #6a32f9;}.quiz-banner{width: 530px !important;margin: auto;}
[class*=sidebar-dark-] .sidebar a {color: #ffffff !important;}
[class*=sidebar-dark] .brand-link {border-bottom: 1px solid #818181;    display: flex;
    flex-direction: column;}.card-title{margin: 0 !important;    font-size: 16px !important;    font-weight: 500;
		padding-left: 7px;}.list-group-unbordered>.list-group-item{font-size: 13px !important;}
[class*=sidebar-dark] .user-panel {border-bottom: 1px solid #6a32f9;    background: #FFC700;padding: 7px;color: #6a32f9;}.main-footer {font-size: 13px;}.brand-font-color{color: #14195F !important;;}.maintenance{width: 770px !important;}.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {background-color: #00022033 !important;color: #FFFFFF !important;}.description-block>.description-text {text-transform: capitalize;}.description-header{ margin-bottom: 0; }.nav-icon{margin-right:10px;}.mandatory_error{color: red;font-size: 13px;    display: block;
    width: 100%;}.navbar-white{z-index: 1;}.h5, h5 {font-size: 1rem !important;}.tab-title{margin-top: 10px;font-weight: 500;}.mb-15{margin-bottom: 15px !important;}.mt-10{margin-top: 10px;}.mt-20{margin-top: 20px;}.mt-100{margin-top: 100px !important;}.sidebar{padding:0;}.w-60{width: 60px;}.h-60{height: 60px;}.w90p{width: 90%;}.profile-user-img{height: 100px;}.info-box .info-box-text, .info-box .progress-description{font-size: 11px;white-space: pre-line !important;height: 35px;}.course-box{cursor: pointer;}.cursor-pointer{cursor: pointer;}.mb-15{margin-bottom: 15px;}.mb-20{margin-bottom: 20px;}
ul.subject-list{list-style: none;}.quiz-title{font-size: 1.5rem;}ul.subject-list li{    border: 1px solid #ddd;padding: 10px;margin-bottom: 10px;cursor: pointer;}a {text-decoration: none !important;}.cup-img{width: 60px;margin-bottom: 10px;}.users-list > li{cursor: pointer;width: 13%;}.users-list > li.active img{ border: 2px solid #6A32F9;}.users-list > li.active a{color: #6A32F9;} .users-list > li img{height: 70px;width: 70px;}ul.inner-breadcrump{list-style: none;display: inline-block;    padding-left: 15px;margin: 0;}ul.inner-breadcrump li{display: inherit;padding-right: 10px;vertical-align: middle;    font-size: 13px;}ul.inner-breadcrump li a{font-size: 15px;font-weight: 600;} ul.inner-breadcrump li:nth-child(2) > a::before, ul.inner-breadcrump li:nth-child(3) > a::before { content: '>>';color: #b5b5b5;padding-right: 10px;text-align: center; }.top-icon{font-size: 12px;margin-right: 10px;cursor: pointer;}.w-300{width: 300px;}.w-40{width: 40px;}.nav-pills .nav-link{border-radius: 0 !important;}.card.card-outline-tabs .card-header a:hover{border-top: 3px solid #f7f7f7;}.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active{border-radius: 0 !important;}.action-text{cursor: pointer;}.btn{font-size: 13px;}.widget-user-image a img{width: 100px; height: 100px;}.widget-user .action-links{list-style-type: none;display: flex;flex-direction: column;justify-content: space-around;align-items: end;}.widget-user .action-links li a {color: #fff;}.widget-user .action-links li:first-child{margin-bottom: 20px;display: flex;}.widget-user .widget-user-username{font-size: 20px;}.badge{white-space: break-spaces;}.capital{text-transform: capitalize;}.mb-20{margin-bottom: 20px;}.PhoneInput {/* This is done to stretch the contents of this component. */display: flex;align-items: center;width: 100%;}.card-primary.card-outline-tabs>.card-header a.active {border: none !important;
    background-color: #6A32F9 !important;
    color: #fff !important;
    border-radius: unset !important;}.view-tutor-details{position: absolute;}.mb-0{margin-bottom: 0px !important;}.main-row{display: flex;align-items: center;justify-content: flex-start;column-gap: 15px;}.main-col{}
.cart-qty{border: 1px solid #cfcfcf;padding: 3px 5px;margin-right: 10px;font-size: 11px;}.cart-product-summary{font-size: 11px;}.cart-product-price{color: #6A32F9;}.nav-link{font-size: 13px !important;}.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #FFFFFF !important;    border-radius: 3px !important;
    background-color: #14195F !important;
}.card-primary:not(.card-outline)>.card-header {
    background-color: #6A32F9 !important;
}.card-primary.card-outline {
    border-top: 3px solid #6A32F9 !important;
}.brand-link p{margin-bottom: 0 !important;}.col-form-label {
    font-size: smaller !important;    
}.error-page {margin: 20px auto 0;width: 400px !important;background: #fff !important;padding: 25px !important;}.error-page>.error-content {margin-left: 0 !important;}
.brand-link .brand-image{max-height: initial !important;margin-right: 0.8rem !important;}
.video-container{

}.css-nvf14r-ToastContainer{z-index: 9999 !important;}
.video-container .custom-icons{
	position: absolute;
    display: none;
    flex-direction: row-reverse;
    left: 20%;
    bottom: 40px;
    color: #fff;}
	.user-panel img {
		height: 2.1rem !important;width: 2.1rem;
	}
.video-container:hover .custom-icons{display: flex;}
.video-container .custom-icons .bookmark{    cursor: pointer;}
.video-container .custom-icons .notes{    cursor: pointer;}
.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
  display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.PhoneInputCountryIcon {
	width: calc(1em * 1.5);
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: 1em;
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: 1em;
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: rgba(0,0,0,0.1);
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 1px rgba(0,0,0,0.5),
		inset 0 0 0 1px rgba(0,0,0,0.5);
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: 0.8;
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: 0.65;
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}
/* Styling native country `<select/>`. */
.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: 0.35em;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
  width: 50px;
}.PhoneInputCountrySelect {position: absolute;top: 0;left: 0;height: 100%;width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}
.PhoneInputCountrySelect[disabled] {cursor: default;}.sidebar-dark-primary{background-color: #6A32F9 !important;}
.navbar-white {
    background-color: #6A32F9 !important;
    color: #ffffff !important;
}
.navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255) !important;
}
.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: 0.3em;
	width: var(--PhoneInputCountrySelectArrow-width);
	height: 0.3em;
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-top: calc(var(--PhoneInputCountrySelectArrow-height) / 2);
	margin-top: var(--PhoneInputCountrySelectArrow-marginTop);
	margin-left: 0.35em;
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: inherit;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: 1px;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: 1px;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	-webkit-transform: rotate(45deg);
	-webkit-transform: var(--PhoneInputCountrySelectArrow-transform);
	        transform: rotate(45deg);
	        transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: 0.45;
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: #03b2cb;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 1px #03b2cb,
		inset 0 0 0 1px #03b2cb;
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;color: #03b2cb;color: var(--PhoneInputCountrySelectArrow-color--focus);
}.otp-boxes .form-control {border: 1px solid #ccc !important;width: 38px !important;border-right: 1px solid #ccc !important;font-size: 0.8rem !important;}.duration{display: inherit;color: #7e7e7e;}
.navbar-badge {font-size: .6rem;font-weight: 300;padding: 2px 4px;position: absolute;right: 4px;top: 3px;}.trash-icon{color:#dc3545}.text-overflow{width: 275px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}i.success{color: #28a745}.generate-otp{display: flex;}.send-otp{margin-top: 14px;margin-left: 50px;}.dropdown-menu{min-width: 285px !important;}.clickable{cursor: pointer;} .quiz-topics{font-size: 15px !important;color: #2c2c2c !important;}.content-header h1 {font-size: 1.5rem;}#quiz-counter {
	color: #6A32F9;font-size: 1.5rem;}.quiz-counter{font-size: 13px;}.mr-10{margin-right: 10px;}
  .quiz-container {
	  padding: 0.25em;
	  max-width: 650px;
	  margin: 1em auto;
  }
  .carousel.carousel-slider{margin: auto !important;}
  ul li.unavailable {
    pointer-events: none;
    position: relative;
}.carousel-container{margin-top: 50px;    margin-bottom: 30px;}
  .main-sidebar {
    z-index: 99 !important;
}
  .quiz-container a {
	  text-decoration: none;
	  color: #333;
  }
  
  #quiz-header,
  #quiz-start-screen,
  #quiz-results-screen,
  #quiz-counter {
	  text-align: center;
  }
  
  #questions {
	margin-top: 1.5em;
  }
  
  .question {
	  font-size: 1.25em;
	  padding-bottom: 1.5rem;
	  font-weight: 500;
  }
  
  .answers {
	  list-style: none;
	  padding: 0;
  }

  .answers li {
	cursor: pointer;
	}	
  
  .answers li:not(:first-child) {
	  margin-top: 1.25em;
  }
  
  .answers a,
  #quiz-controls {
	  background: #f3f3f3;
	  border: .1em solid #efefef;
	  color: #525252;
  }
  .answers a {
	  display: block;
	  padding: 0.75em 1em;
	  margin-bottom: 0.5em;
  }
  .back-btn{margin: 0;padding: 0 5px !important;}
  .answers a.correct {
	  background: #31b72f;
	  border-color: #2db12b;
  }
  .answers a.incorrect {
	  background: #e64646;
	  border-color: #e04040;
  }
  
  .answers a.correct,
  .answers a.incorrect {
	  color: #fff;
  }
  .unavailable{pointer-events: none;}
  #quiz-controls {
	  color: #111;
	  padding: 1.25em 1em;
	  text-align: center;
	  margin-top: 3.5em;
  }
  
  #quiz-results {
	  font-size: 1.25em;
  }
  
  #quiz-buttons a,
  .quiz-container .quiz-button,
  .answers a,
  #quiz-controls {
	  border-radius: .25em;
  }
  
  #quiz-buttons a,
  .quiz-container .quiz-button {
	  display: inline-block;
	  padding: 0.5em 1em;
	  min-width: 8em;
  }
  
  .quiz-button,
  #quiz-buttons a {
	  background-color: #FFC700;
  border-color: transparent;
  color: rgba(0, 0, 0, .7);
  
  width: 10rem;
	  transition: 0.2s ease;
  }
  
  .quiz-button:hover,
  #quiz-buttons a:hover {
	  background-color: #FFC700;
	  border-color: transparent;
	  color: rgba(0, 0, 0, .7);
	  transition: 0.2s ease;
  }
  
  /* Quiz State Overrides */
  iframe {display: block;border: none;height: 255vh;width: 73vw;}.w-100p{width: 100%;}.quiz-results-state #quiz-buttons a {background: #6A32F9;border-color: #6A32F9;margin: 1em;color: #fff;}.invoice p {text-align: justify;}ul.listing li, ol.listing li {font-size: 15px; padding-bottom: 10px;}[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active, [class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:focus, [class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link.active:hover {background-color:#14195F33 !important;color: #fdfdfd !important;}.brand-primary-text{color: #6A32F9 !important;} .strikethrough{text-decoration: line-through;}.d-block{display: block;}.full-border{border: 1px solid #ced4da !important;}
